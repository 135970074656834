<template>
  <v-container fluid>
   <v-row v-if="!currentCase.hasBudgetItems">
      <v-col class="mt-0 pt-0 pb-0" cols="12">
     <v-card>
          <v-card-text>
            <v-icon size="32" color="orange darken-2">mdi-alert</v-icon>
           You have no budget items associated with this participant.  
           Budget items must be created before creating any goals.


          </v-card-text>
        </v-card>
      </v-col>
   </v-row>
    <v-row v-if="currentCase.hasBudgetItems">
      <v-col class="mt-0 pt-0 pb-0" cols="12">
        
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>
            <v-spacer></v-spacer>
            <v-select
            label="Status"
              v-model="status"
              :items="[{text: 'In Progress', value: 'In Progress'}, {text: 'Completed', value:  'Completed'},{text: 'Draft', value:  'Draft'}]"
              @change="toggleStatus"
            ></v-select>
            <v-spacer></v-spacer>
            <v-select
            label="State"
              v-model="deleted"
              :items="[{text: 'Active goals', value: false}, {text: 'Deleted goals', value: true}]"
              @change="toggleDeleted"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn @click="addGoal">Add goal</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="headers"
          :items="goals"
          :loading="loading"
          item-key="index"
          class="mr-2"
          v-if="!deleted"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.id}}</td>
              <td>{{ props.item.title}}</td>
              <td style="max-width:400px">{{ props.item.description}}</td>
              <td>{{ props.item.status}}</td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>

                <v-icon small @click="deleteItem(props.item)" v-if="isAdmin" >mdi-delete</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="deletedHeaders"
          :items="deletedGoals"
          :loading="loading"
          item-key="index"
          class="mr-2"
          v-if="deleted"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.id}}</td>
              <td>{{ props.item.title}}</td>
              <td style="max-width:400px">{{ props.item.description}}</td>
              <td>{{ props.item.status}}</td>

              <td>{{props.item.deletedOn==undefined ?"":moment(props.item.deletedOn.toDate()).format("YYYY-MM-DD hh:mm")}}</td>

              <td>{{props.item.deletedBy==undefined ?"":props.item.deletedBy.displayName}}</td>

              <td>
                <v-icon small @click="unDeleteItem(props.item)"  v-if="isAdmin">mdi-undo-variant</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6"></v-col>
       
       <span style="font-size:.5em; margin-left:10px">articipant Id:{{currentCase.id}} | NoteID: {{vm.id}}</span>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as goalsService from "../../../services/goalService";
import moment from "moment";
Vue.use(VeeValidate);

export default {
  name: "goals",

  data: () => ({
    vm: {},
    isAdmin : false,
    dialog: false,
    valid: true,
    deleted: false,
    status: "In Progress",
    goals: [],
    deletedGoals: [],
    totalItems: 0,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "ID", value: "id" },
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Status", value: "status" },
      { text: "", value: "actions" }
    ],
    deletedHeaders: [
      { text: "ID", value: "id" },
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Status", value: "status" },
      { text: "Deleted On", value: "deletedOn" },
      { text: "Deleted By", value: "deletedBy" },

      { text: "", value: "actions" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal(state) {
      return state.currentGoal;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    init() {
      this.vm = {};
         if (
        this.user.roles.indexOf("System Administrator") > -1 ||
        this.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        this.isAdmin = true;
      }
      this.getGoals();
    },
    getGoals(){
      goalsService
        .listByStatus(this.currentOrganisation.id, this.currentCase.id, this.status)
        .then(result => {
          let goals = [];
          result.docs.map(doc => {
            const goal = doc.data();
            goal.id = doc.id;
            goals.push(goal);
          });
          this.goals = goals;
        });
    },
    close() {
      this.$router.push("/participant-management/current-participant");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    editItem(item) {
      this.$store.commit("setCurrentGoal", item);
      this.$router.push("/goal-details");
    },
    addGoal() {
      this.$store.commit("setCurrentGoal", null);
      this.$router.push("/goal-details");
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    toggleStatus(){
      if (this.deleted) {
        this.getDeleted();
      } else {
        this.getGoals();
      }
    },
    toggleDeleted() {
      //this.deleted = !this.deleted;
      if (this.deleted) {
        this.getDeleted();
      }
    },
    getDeleted() {
      goalsService
        .listRemovedByStatus(this.currentOrganisation.id, this.currentCase.id, this.status)
        .then(result => {
          let goals = [];
          result.docs.map(doc => {
            const goal = doc.data();
            goal.id = doc.id;
            goals.push(goal);
          });

          this.deletedGoals = goals.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    deleteItem(item) {
      const me = this;
      const index = me.goals.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        goalsService
          .remove(
            me.currentOrganisation.id,
            me.currentCase.id,
            item.id,
            me.user
          )
          .then(result => {
            console.log(result);
            me.goals.splice(index, 1);
          });
      }
    },
    unDeleteItem(item) {
      const me = this;
      const index = me.goals.indexOf(item);

      goalsService
        .unRemove(me.currentOrganisation.id, me.currentCase.id, item.id)
        .then(result => {
          console.log(result);
          me.goals.push(item);
          me.deletedGoals.splice(index, 1);
        });
    },
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


